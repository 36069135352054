import { GeoPosition } from "react-native-geolocation-service";
import { Amount } from "../../core/amount/amount";
import { FormInput } from "../../core/data-forms/form-input-types";
import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Transaction } from "../accounting-transaction/transaction/transaction";
import { PincodeSubmission } from "../pincode/pincode";
import { AccountBlockedErrorFromErrorResponse, isAccountBlockedErrorResponse } from "../pincode/pincode-error";
import { ConfirmationMode } from "../transactions/transaction-request";
import { BillCategory, BillIssuer, BillIssuerType } from "./bill";

export class BillService {
  public constructor(private readonly apiService: ConnectedApiService) {}

  public async initPayBill(issuerId: string | number, location?: GeoPosition | null) {
    try {
      const response = await this.apiService.instance.post<{ paybillSessionId: string }>("/transactions/paybill", {
        location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        billIssuerId: issuerId,
      });
      return response.data;
    } catch (e) {
      logger.debug("BillService", "Init pay bill failed", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async payBill(billSession: string, inputValues: { [key: string]: string | Amount }) {
    try {
      const response = await this.apiService.instance.patch<PayBillDTO>(`/transactions/paybill/${billSession}`, {
        inputs: inputValues,
      });

      if (response.status === 200) {
        return { data: response.data as InputBillDTO, done: false as const };
      } else {
        return { data: response.data as PreauthBillDTO, done: true as const };
      }
    } catch (e) {
      logger.debug("BillService", "Pay bill failed", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async validateBillPayment(
    billSession: string,
    confirmationMode: ConfirmationMode,
    pincode?: PincodeSubmission,
  ) {
    try {
      const response = await this.apiService.instance.post<ValidateBillPaymentDTO>(
        `/transactions/paybill/${billSession}`,
        { confirmationMode, pincode },
      );
      return response.data;
    } catch (e) {
      logger.debug("BillService", "Validate bill payment failed", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async fetchIssuers(billItemId?: BillIssuerType): Promise<(BillIssuer | BillCategory)[]> {
    try {
      const response = await this.apiService.instance.get<BillIssuer[]>("/billers", {
        ...(billItemId && {
          params: {
            billItemId,
          },
        }),
      });
      return response.data;
    } catch (e) {
      logger.debug("BillService", "Fetch issuers failed", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async saveReference(issuer: BillIssuer, value: string) {
    try {
      const response = await this.apiService.instance.post<BillIssuer>(`/billers/${issuer.id}/references`, {
        reference: value,
      });
      return response.data;
    } catch (e) {
      logger.debug("BillService", "Save reference failed", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async deleteReference(issuer: BillIssuer) {
    if (!issuer.references) {
      logger.error(`Trying to remove reference from issuer ${issuer}`);
      return issuer;
    }
    try {
      const response = await this.apiService.instance.delete<BillIssuer>(
        `/billers/${issuer.id}/references/${issuer.references[0]}`,
      );
      return response.data;
    } catch (e) {
      logger.debug("BillService", "Delete reference failed", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }
}

export type PayBillResult =
  | {
      data: InputBillDTO;
      done: false;
    }
  | {
      data: PreauthBillDTO;
      done: true;
    };

type PayBillDTO = PreauthBillDTO | InputBillDTO;

interface PreauthBillDTO {
  metadata: {
    confirmationMode: ConfirmationMode;
    transaction: Transaction;
  };
  data: AdditionalData;
}

interface InputBillDTO {
  inputs: FormInput[];
}

interface ValidateBillPaymentDTO {
  metadata: {
    transaction: Transaction;
  };
  data?: {
    info?: string;
    additional?: string;
    voucher?: string;
  };
}

export type ValidateBillPayment = ValidateBillPaymentDTO;

export interface AdditionalData {
  info?: string;
  additional?: string;
}
