import {
  AccountBlockedErrorFromErrorResponse,
  isAccountBlockedErrorResponse,
  isRecipientNotFoundByInvalidPhoneError,
} from "../../pincode/pincode-error";
import {
  AccountOrRecipient,
  CustomerInstructionInformation,
  CustomerInstructionResult,
  PaymentAddress,
  PaymentContracts,
  PaymentIdentificationMode,
  PaymentNetwork,
  PaymentTransaction,
  PaymentType,
  ServiceLevel,
  getBlankCustomerInstruction,
} from "../customer-instruction";
import { ConfirmationMode, TransactionCallType, TransactionRequest } from "../transaction-request";

import { GeoPosition } from "react-native-geolocation-service";
import { Amount } from "../../../core/amount/amount";
import { logger } from "../../../core/logging/logger";
import { ConnectedApiService } from "../../../core/net/connected-api-service";
import { ConnectedServiceDomainApiService } from "../../../core/net/connected-service-domain-api-service";
import { Account } from "../../account/account";
import { ClientManager } from "../../client/client-manager";
import { CheckPincodeService } from "../../pincode/check-pincode-service";
import { PincodeSubmission } from "../../pincode/pincode";

export class TransferService {
  public constructor(
    private apiService: ConnectedApiService,
    private serviceDomainApiService: ConnectedServiceDomainApiService,
    private clientManager: ClientManager,
    private checkPincodeService: CheckPincodeService,
  ) {}

  public async startSimpleTransfer(amount: Amount, phoneNumber: string, label?: string, location?: GeoPosition | null) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-simple-transfer", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { amount, label, phoneNumber },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start transfer", e);
      if (isAccountBlockedErrorResponse(e) || isRecipientNotFoundByInvalidPhoneError(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmSimpleTransfer(
    confirmationMode: ConfirmationMode,
    amount: Amount,
    phoneNumber: string,
    label?: string,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-simple-transfer", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          amount,
          label,
          phoneNumber,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to confirm transfer", e);
      if (isAccountBlockedErrorResponse(e) || isRecipientNotFoundByInvalidPhoneError(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startTransfer(recipientId: string, amount: Amount, label?: string, location?: GeoPosition | null) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-transfer", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { recipientId, amount, label },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-transfer", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          recipientId,
          amount,
          label,
        },
      });
      return response.data;
    } catch (e) {
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startPayout(
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/payout", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { recipientId, amount, purpose, srcAccountId },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start payout", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmPayout(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/payout", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          recipientId,
          amount,
          srcAccountId,
          purpose,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to confirm payout", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startCashTransfer(
    recipientId: string,
    amount: Amount,
    label: string | undefined = undefined,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-cash-transfer", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { recipientId, amount, label },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start cash transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmCashTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-cash-transfer", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          recipientId,
          amount,
          label,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to confirm cash transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  // CUSTOMER INSTRUCTION

  public async getPaymentNetworks() {
    try {
      const response = await Promise.all([
        this.serviceDomainApiService.instance.get<{ items: PaymentNetwork[] }>("/payment-networks/configuration"),
        this.serviceDomainApiService.instance.get<PaymentContracts>("/payment-service-contract"),
      ]);
      const networks = response[0].data;
      const contracts = response[1].data.creditTransferEmission;
      const paymentNetworks = contracts
        .filter((contract) => contract.serviceLevel !== "ON_US")
        .map((contract) => {
          const network = networks.items.find((n) => n.serviceLevel === contract.serviceLevel);
          return {
            ...network,
            ...contract,
          };
        });
      return paymentNetworks;
    } catch (e) {
      logger.debug("TransferService", "Failed to get payment networks", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startCustomerInstruction(
    paymentNetwork: PaymentNetwork,
    amount: Amount,
    sourceAccount: Account,
    destinationAccountOrRecipient: AccountOrRecipient,
    pincode?: PincodeSubmission,
    label?: string,
    creditorAddress?: PaymentAddress,
  ) {
    const client = this.clientManager.client.get();
    const customerInstruction = getBlankCustomerInstruction();
    const identificationModes = paymentNetwork.identificationMode;
    const needPhoneNumberIdentificationMode =
      identificationModes.includes(PaymentIdentificationMode.PHONE) && identificationModes.length === 1;
    const needAccountIdentificationMode =
      identificationModes.includes(PaymentIdentificationMode.ACCOUNT_NUMBER) && identificationModes.length === 1;
    try {
      if (pincode) {
        await this.checkPincodeService.checkPincode(pincode);
      }
      if (label) {
        customerInstruction.paymentTransaction.paymentTransactionDedicatedInformations = {
          remittanceInformation: {
            value: label,
            type: "UNSTRUCTURED",
          },
        };
      }
      customerInstruction.customerInstructionInformation.customerInstructionTypeInformation.serviceLevel =
        paymentNetwork.serviceLevel;
      customerInstruction.customerInstructionInformation.paymentInstrument =
        paymentNetwork.serviceLevel === ServiceLevel.SCT_INST ? "CreditTransferInst" : "CreditTransfer";
      customerInstruction.customerInstructionInformation.customerInstructionReference = `CINSTR${new Date().toISOString()}`;
      customerInstruction.paymentTransaction.paymentTransactionAmountInformation.instructedAmount = amount;
      customerInstruction.customerInstructionInformation.requestedExecutionDate = new Date()
        .toISOString()
        .substring(0, 10);
      customerInstruction.customerInstructionOrderingParties.debtor = {
        ...customerInstruction.customerInstructionOrderingParties.debtor,
        name: client ? `${client.firstName} ${client.lastName}` : sourceAccount.label,
        accountId: {
          value: needAccountIdentificationMode ? sourceAccount.id : sourceAccount.iban?.replaceAll(" ", ""),
          type: needAccountIdentificationMode ? PaymentType.OTHER : PaymentType.IBAN,
        },
        ...(needPhoneNumberIdentificationMode && {
          id: {
            value: client?.contactphone,
            type: PaymentType.PHONE,
          },
          type: null,
        }),
      };
      customerInstruction.customerInstructionOrderingParties.initiatingParty = {
        ...customerInstruction.customerInstructionOrderingParties.initiatingParty,
        name: `${client?.firstName} ${client?.lastName}`,
        id: {
          value: client?.contactphone,
          type: PaymentType.PHONE,
        },
      };
      customerInstruction.paymentTransaction.paymentTransactionParties.creditor = {
        ...customerInstruction.paymentTransaction.paymentTransactionParties.creditor,
        name: destinationAccountOrRecipient.label || destinationAccountOrRecipient.name,
        ...(creditorAddress && {
          postalAddress: creditorAddress,
        }),
        ...(destinationAccountOrRecipient.iban && {
          accountId: {
            value: destinationAccountOrRecipient.iban?.replaceAll(" ", ""),
            type: PaymentType.IBAN,
          },
        }),
        ...(destinationAccountOrRecipient.accountReference && {
          accountId: {
            value: destinationAccountOrRecipient.accountReference,
            type: PaymentType.OTHER,
          },
        }),
        ...(destinationAccountOrRecipient.phone && {
          id: {
            value: destinationAccountOrRecipient.phone,
            type: PaymentType.PHONE,
          },
        }),
      };
      const response = await this.serviceDomainApiService.instance.post(
        "/customer-instructions/credit-transfers/submit",
        customerInstruction,
      );
      return response.data as CustomerInstructionResult;
    } catch (e) {
      logger.debug("TransferService", "Failed to start transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async submitCustomerInstruction(id, token): Promise<CustomerInstructionResult> {
    try {
      const response = await this.serviceDomainApiService.instance.post(
        `/customer-instructions/credit-transfers/${id}/submit`,
        null,
        {
          headers: {
            "SCAP-token": token,
          },
        },
      );
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to submit customer instruction", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async getAuthenticationSession(customerInstructionReference: string) {
    try {
      const response = await this.serviceDomainApiService.instance.get(
        `/authentications/sessions/${customerInstructionReference}`,
      );
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to get authentication session", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async getCustomerInstruction(id: string | number) {
    try {
      const response = await this.serviceDomainApiService.instance.get(`/customer-instructions/${id}`);
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to get customer instruction", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }
  public async submitCreditTransferSinglePayment(
    customerInstructionInformation?: CustomerInstructionInformation,
    paymentRTransaction?: PaymentTransaction,
  ): Promise<any> {
    try {
      const response = await this.serviceDomainApiService.instance.post(
        "/customer-instructions/r-credit-transfers/submit",
        {
          customerInstructionInformation: {
            ...customerInstructionInformation,
            paymentInstrument: "CreditTransferRecall",
          },
          paymentRTransaction: {
            ...paymentRTransaction,
          },
        },
      );
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to submit Credit Transfer Single Payment", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async getPaymentTransaction(id: string | number): Promise<PaymentTransaction> {
    try {
      const response = await this.serviceDomainApiService.instance.get(`/payment-transactions/${id}`);
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to get payment transaction", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }
}
