import { Account, AccountStatus } from "./account";

import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Paginated } from "../../utils/pagination";

export class AccountService {
  public constructor(private apiService: ConnectedApiService) {}

  public async fetchAccounts() {
    try {
      const response = await this.apiService.instance.get<Paginated<Account>>("/accounts", {
        params: {
          status: [
            AccountStatus.Opened,
            AccountStatus.Blocked,
            AccountStatus.DebitBlocked,
            AccountStatus.CreditBlocked,
          ],
          limit: 200,
        },
      });
      //TODO keep until next part of ticket (vc-1086) is done
      // const fake = response.data.items.filter((e) => +e.id === +"17000660633")[0];
      // console.log(fake);
      // return { ...response.data, items: [fake], count: 1, total: 1 };
      return response.data;
    } catch (e) {
      logger.debug("AccountService", "Failed to fetch accounts", e);
      throw e;
    }
  }
}
